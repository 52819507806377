 
  .box {
    display: flex;
    transition: all 0.3s ease-in-out;
    user-select: none;
  }
  
  /* With prefixes */
  .box {
    display: -webkit-box; /* Old versions of Safari and iOS */
    display: -moz-box; /* Older versions of Firefox */
    display: -ms-flexbox; /* IE10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome */
    display: flex; /* Modern browsers */
    
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
