@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --primary-color: #296D9C;
    --p-text-color: #707070;
    --goal-title-weight: 600;
    --page-margin: 5rem; /* Adjust as needed */
    --font-family: 'DM Sans', sans-serif; 
  }
}


body {
  font-family: 'DM Sans', sans-serif;
   font-optical-sizing: auto;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.margin {
  margin: var(--page-margin);
}
.backdrop-blur-sm {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
.para {
  color: var(--p-text-color);
}

.keyPoints {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease; /* Add transition effect */
  border-radius: 50px;
}

.keyPoints:hover {
  background-color: #E8E8E8;
}

.line::after {
  content: "";
  display: block;
  position: relative;
  background-color: #E8E8E8;
  height: 1px;
  width: 100%;
  /* left: 40px; Uncomment and adjust as needed */
}


::-webkit-scrollbar {
  width: 5px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #929191;
  border-radius: 6px;
}


::-webkit-scrollbar-thumb:hover {
  background: #555;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}





.timeline {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.timeline-year {
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
}

.timeline-year:hover {
  background-color: #66a7d56f;
  border: 1px solid #296D9C;
  border-radius: 10%;
}

 /* Slick carousel styles */
.slick-slider {
  width: 96%;
  margin: 0 auto;
}


.scroll {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
}








